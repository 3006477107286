import axios from 'axios';
const url = 'customers/Api/';

export default {
    namespaced: true,
    actions: {
        async create_mambuCustomer(_, id) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Customers/Create`, id, {
                    headers: {
                        accept: 'text/plain',
                        'Content-Type': 'application/json',
                    },
                });
                this._vm.$bvToast.toast('Successfully customer created.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating customer', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async create_mambuLoan({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Loans/Create`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully loan created.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error creating loan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_byLoanApplicationId(_, id) {
            try {
                const response = await axios.get(`${url}OMMiddleware/OCS/Loans/GetByLoanApplicationId/${id}`);
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_ocsLoanByLoanId(_, id) {
            try {
                const response = await axios.get(`${url}OMMiddleware/OCS/Loans/GetByLoanId/${id}`);
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_mambuLoanByLoanId(_, id) {
            try {
                const response = await axios.get(`${url}OMMiddleware/Mambu/Loans/${id}`);
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_customerByEncodedKey(_, id) {
            try {
                const response = await axios.get(`${url}OMMiddleware/Mambu/Customers/GetByID?ID=${id}`);
                return response?.data?.dataResult ?? null;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_mambuSchedule(_, id) {
            try {
                const response = await axios.get(`${url}OMMiddleware/Mambu/Loans/Schedule/${id}`);
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_mambuLoans(_, payload) {
            try {
                const response = await axios.get(
                    `${url}OMMiddleware/Mambu/Loans/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&Status=${payload.status}&SortDirection=${payload.sortDirection}&SortProperty=${payload.sortProperty}`,
                );
                return response.data ?? null;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_createdLoans(_, payload) {
            try {
                const response = await axios.get(
                    `${url}OMMiddleware/OCS/Loans/GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&Status=${payload.status}&SortDirection=${payload.sortDirection}&SortProperty=${payload.sortProperty}`,
                );
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_mambuState({ rootGetters }, id) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Loans/ChangeState`, {
                    loanId: id,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully state changed.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating state', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async make_disbursement({ rootGetters }, id) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Loans/MakeDisbursement`, {
                    loanId: id,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Disbursement successfully completed.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error making disbursement', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return false;
            }
        },

        async update_mambuCustomer(_, id) {
            try {
                const response = await axios.put(`${url}OMMiddleware/Mambu/Customers/Update`, id, {
                    headers: {
                        accept: 'text/plain',
                        'Content-Type': 'application/json',
                    },
                });
                this._vm.$bvToast.toast('Successfully customer updated.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating customer', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async preview_schedule({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Loans/PreviewSchedule`, {
                    loanId: payload.loanId,
                    loanApplicationId: payload.loanApplicationId,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully customer updated.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating customer', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async reschedule({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Loans/Reschedule`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully reschedule loan.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error rescheduling loan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async refinance({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}OMMiddleware/Mambu/Loans/Refinance`, {
                    ...payload,
                    createdBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Successfully refinance loan.', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error in refinancing the loan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_loansByEncodedKey(_, id) {
            try {
                const response = await axios.get(
                    `${url}OMMiddleware/Mambu/Loans/GetAllByCustomerEncodedKey?EncodedKey=${id}`,
                );
                return response;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loans', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
    },
};
