import axios from 'axios';
const url = 'loans/Api/LoanApplications/';

export default {
    namespaced: true,
    state() {
        return {
            applications: [],
            totalApplications: 0,
            loanApp: null,
            loanApplication: null,
        };
    },
    getters: {
        getApplications(state) {
            return state.applications;
        },
        getTotalApplications(state) {
            return state.totalApplications;
        },
        getLoan(state) {
            return state.loanApp;
        },
        getLoanApplication(state) {
            return state.loanApplication;
        },
        getCustomerTypes(state) {
            const items = [];
            items.push({
                text: 'Applicant',
                value: state.loanApplication.applicantInfo.id,
            });

            if (state.loanApplication.applicantInfo.additionalDetails.spousesId) {
                items.push({
                    text: `Spouse's`,
                    value: state.loanApplication.applicantInfo.additionalDetails.spousesId,
                });
            }

            if (state.loanApplication.coApplicantInfo.id !== 0) {
                items.push({
                    text: 'Co-Applicant',
                    value: state.loanApplication.coApplicantInfo.id,
                });
            }
            if (state.loanApplication.guarantorInfo.id !== 0) {
                items.push({
                    text: 'Guarantor',
                    value: state.loanApplication.guarantorInfo.id,
                });
            }
            return items;
        },
    },
    mutations: {
        SET_APPLICATIONS(state, applications) {
            state.applications = applications;
        },
        SET_TOTAL_APPLICATIONS(state, totalApplications) {
            state.totalApplications = totalApplications;
        },
        SET_LOAN_APPLICATION(state, loan) {
            state.loanApp = loan;
        },
        SET_FULL_LOAN_APPLICATION(state, loan) {
            state.loanApplication = loan;
        },
    },
    actions: {
        async get_dashboardInformation() {
            try {
                const response = await axios.get(`${url}GetDashboardInformation`);
                return response.data.dataResult;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting applications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_allApplications({ commit, rootGetters }, payload) {
            try {
                let user = rootGetters['auth/getUser'];
                let path = `${url}GetAll?Page=${payload.skip}&RecordsPerPage=${payload.take}&SortDirection=${payload.sortDirection}&SortProperty=${payload.sortProperty}`;

                if (payload.slug) path = path + `&Slug=${payload.slug}`;
                if (payload.slug == 'assigned') {
                    path += `&AgentId=${user.Id}`;
                }

                path = path + `&SearchParam=${payload.query}`;
                const response = await axios.get(path);
                commit('SET_APPLICATIONS', response.data.dataResult);
                commit('SET_TOTAL_APPLICATIONS', response.data.dataRecords);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting applications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_loanById({ commit }, id) {
            try {
                const response = await axios.get(`${url}GetById/${id}`);
                commit('SET_LOAN_APPLICATION', response.data.dataResult);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_loanApplicationsByCustomerId(_, id) {
            try {
                const response = await axios.get(`${url}GetByCustomerId/${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loan applications', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_loanApplicationById({ commit }, id) {
            try {
                const response = await axios.get(`${url}GetFullDetailsById/${id}`);
                commit('SET_FULL_LOAN_APPLICATION', response.data.dataResult);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_loanApplicationByLoanId(_, id) {
            try {
                const response = await axios.get(`${url}GetFullDetailsByLoanId/${id}`);
                return response.data;
            } catch (error) {
                // const msg = error?.response?.data?.messageResult ?? 'Error getting loan';
                // this._vm.$bvToast.toast(msg, {
                //     title: 'Warning',
                //     variant: 'warning',
                //     solid: true,
                // });
            }
        },

        async update_loanOfficer(_, payload) {
            try {
                let path = `${url}UpdateLoanOfficer?LoanApplicationId=${payload.loanId}&AgentId=${payload.agentId}`;
                const response = await axios.put(path);
                this._vm.$bvToast.toast('Officer updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating loan officer', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_typeSignature({ rootGetters }, payload) {
            try {
                let path = `${url}UpdateIsDigitalSignature?LoanApplicationId=${payload.id}&isDigitalSignature=${payload.isDigitalSignature}&ModifiedBy=${rootGetters['auth/getUser'].Id}`;
                const response = await axios.put(path);
                this._vm.$bvToast.toast('Type of signature updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating signature', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_coApplicant({ rootGetters }, payload) {
            try {
                let path = `${url}UpdateCoApplicantId?LoanApplicationId=${payload.id}&CoApplicantId=${payload.coApplicantId}&ModifiedBy=${rootGetters['auth/getUser'].Id}`;
                const response = await axios.put(path);
                this._vm.$bvToast.toast('Co Applicant updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating Co Applicant', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_guarantor({ rootGetters }, payload) {
            try {
                let path = `${url}UpdateGuarantorId?LoanApplicationId=${payload.id}&GuarantorId=${payload.guarantorId}&ModifiedBy=${rootGetters['auth/getUser'].Id}`;
                const response = await axios.put(path);
                this._vm.$bvToast.toast('Guarantor updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating Guarantor', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async update_incomes({ rootGetters }, payload) {
            try {
                let path = `${url}UpdateIncomes`;
                const response = await axios.put(path, {
                    LoanApplicationId: payload.id,
                    body: payload.body,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Incomes updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating Incomes', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async update_expenses({ rootGetters }, payload) {
            try {
                let path = `${url}UpdateExpenses`;
                const response = await axios.put(path, {
                    LoanApplicationId: payload.id,
                    body: payload.body,
                    ModifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Expenses updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating Expenses', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },
        async get_purposes() {
            try {
                const response = await axios.get(`${url}GetPurposes`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting purposes', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_loanApplication({ rootGetters }, applicationData) {
            try {
                const response = await axios.post(`${url}Add`, {
                    ...applicationData,
                    createdBy: rootGetters['auth/getUser'].Id,
                    agentId: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Application added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding application', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return false;
            }
        },

        async refinance_loanApplication({ rootGetters }, applicationData) {
            try {
                const response = await axios.post(`${url}Refinance/Add`, {
                    ...applicationData,
                    createdBy: rootGetters['auth/getUser'].Id,
                    origin: 2,
                });
                this._vm.$bvToast.toast('Application added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding application', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });

                return false;
            }
        },

        async reschedule_loanApplication({ rootGetters }, applicationData) {
            try {
                const response = await axios.post(`${url}Reschedule/Add`, {
                    ...applicationData,
                    createdBy: rootGetters['auth/getUser'].Id,
                    origin: 2,
                });
                this._vm.$bvToast.toast('Application added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });

                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding application', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });

                return false;
            }
        },

        async update_loanApplication({ rootGetters }, applicationData) {
            try {
                const response = await axios.put(`${url}Update`, {
                    ...applicationData,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Application updated successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error updating application', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return false;
            }
        },

        async get_monthlyPayment(_, payload) {
            try {
                const response = await axios.get(
                    `${url}GetMonthtlyPayment?Amount=${payload.amount}&Months=${payload.months}&LoanTypeId=${payload.loanType}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting monthly payment', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_monthsByLoanType(_, payload) {
            try {
                const response = await axios.get(
                    `${url}GetMonthsByLoanType?Amount=${payload.amount}&LoanTypeId=${payload.loanType}`,
                );
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting months', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_loanRange(_, id) {
            try {
                const response = await axios.get(`${url}GetLoanRange?LoanTypeId=${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('An error has occurred in the get min and max amount', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async refreshApplicantsInfo({ commit, rootGetters }, id) {
            try {
                const response = await axios.put(`${url}RefreshApplicantsInformation`, {
                    id,
                    modifiedBy: rootGetters['auth/getUser'].Id,
                });
                commit('SET_FULL_LOAN_APPLICATION', response.data.dataResult);
                this._vm.$bvToast.toast('Applicants information refreshed successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response;
            } catch (error) {
                console.log(error);
                this._vm.$bvToast.toast('An error has occurred in the refresh applicants information', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        // GetBySourceLoanId/{SourceLoanId}

        async get_loanBySourceLoanId(_, id) {
            try {
                const response = await axios.get(`${url}GetBySourceLoanId/${id}`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting loan', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async get_allProcessStatusCatalog() {
            try {
                const response = await axios.get(`${url}ProcessStatusCatalog/GetAllParents`);
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error getting process status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
            }
        },

        async add_processStatus({ rootGetters }, payload) {
            try {
                const response = await axios.post(`${url}ProcessStatus/Add`, {
                    ...payload,
                    createdByUser: rootGetters['auth/getUser'].Id,
                });
                this._vm.$bvToast.toast('Process status added successfully', {
                    title: 'Success',
                    variant: 'success',
                    solid: true,
                });
                return response.data;
            } catch (error) {
                this._vm.$bvToast.toast('Error adding process status', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                });
                return false;
            }
        },
    },
};
