import dayjs from 'dayjs';

export function formatDate(date) {
    if (!date) return 'N/D';
    return dayjs(date).format('DD/MM/YYYY');
}

export function formatCurrency(value) {
    //Conver to AWG currency
    return value.toLocaleString('en-AW', { style: 'currency', currency: 'AWG' });
}
